import { Link } from 'react-router-dom';
import websiteLogo from './website-logo.png';
import './App.css';

const App = () => (
  <div className="main-container">
    <h1 className="logo">
      <Link to="/">
        <img src={websiteLogo} alt="memtones logo" style={{ maxWidth: 750 }} />
      </Link>
    </h1>
    <p className="text">💡 your 🧠 with ♫</p>
    <Link className="link" to="/privacy">
      Privacy Policy
    </Link>
    <Link className="link" to="/tos">
      Terms of Service
    </Link>
  </div>
);

export default App;
