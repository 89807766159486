import './App.css';

const Privacy = () => (
  <div className="main-container">
    <section style={{ textAlign: 'left', paddingLeft: 10, paddingRight: 10, maxWidth: 756 }}>
      <h3>Memtones Privacy Policy</h3>
      <h4>General</h4>
      <p>
        At Memtones, we know you care about your personal information, so we have prepared this privacy policy (our
        "Privacy Policy") to explain how we collect, use, and share it. By using or accessing the Service, you agree to
        the terms of this Privacy Policy. We may update our Privacy Policy to reflect changes to our information
        practices. If we do this and the changes are material, we will post a notice that we have made changes to this
        Privacy Policy on the Memtones Website for at least 7 days after the changes are made, and we will indicate the
        date these terms were last revised at the bottom of the Privacy Policy. Any revisions to this Privacy Policy
        will become effective the earlier of (i) the end of that 7-day period or (ii) the first time you access or use
        the Service after any such changes.
      </p>
      <h4>Information We Collect</h4>
      <p>
        When you use a Memtones Service, you provide us with two types of information: (i) information you submit via
        the Service and (ii) information regarding your use of the Service collected by us as you interact with the
        Service.
      </p>
      <p>
        When you enter a Memtones Service, we collect your browser type and your IP address (a unique address that
        identifies your computer on the Internet). When you use our mobile Application, we collect your device type,
        your device ID, and your IP address. In addition, we store certain information from your browser using
        "cookies." A cookie is a piece of data stored on the user's computer tied to information about the user. We use
        session ID cookies to confirm that users are logged in. If you do not want information collected through the use
        of cookies, there is a simple procedure in most browsers that allows you to deny or accept the cookie feature;
        however, you should note that cookies may be necessary to provide you certain features available on the Website
        (e.g., customized delivery of information).
      </p>
      <p>
        Through the registration process you may provide us with your name and email address, birthdate, and other
        information that may be requested during the registration process.
      </p>
      <p>
        When you use the Service you may submit information and content to your profile, generate Activity Data through
        engaging in activities on the Service, or send messages and otherwise transmit information to other users. We
        store this information so that we can provide you the Service and offer personalized features.
      </p>
      <p>
        We do not share your personal information with third-parties unless it is necessary to offer the Service or
        required by law (see below for specifics).
      </p>
      <h4>Use of Information Obtained by Memtones</h4>
      <p>
        We may use your contact information to send you notifications regarding new services offered by Memtones that we
        think you may find valuable. Memtones may also send you service-related announcements from time to time through
        the general operation of the Service. Generally, you may opt out of such emails.
      </p>
      <p>
        Profile information is used by Memtones to be presented back to and edited by you when you access the Service
        and to be presented to other users. In some cases, other users may be able to supplement your profile, including
        by submitting comments (which can be deleted by you).
      </p>
      <p>
        Memtones may use or share aggregate or anonymous data collected through the Service, including Activity Data,
        for purposes such as understanding or improving the service.
      </p>
      <h4>Sharing Your Personally-Identifiable Information with Third Parties</h4>
      <p>
        Memtones shares your personally-identifiable information only when it is reasonably necessary to offer the
        Service, legally required, or permitted by you. For example:
      </p>
      <p>
        We may provide personally-identifiable information to service providers who help us bring you the Service, such
        as hosting the Service at a co-location facility or sending email updates. In connection with these operations,
        our service providers may have access to personally-identifiable information for a limited time. When we utilize
        service providers for processing any personally-identifiable information, we implement reasonable contractual
        protections limiting the use of that personally-identifiable information to the provision of services to
        Memtones.
      </p>
      <p>
        We may be required to disclose personally-identifiable information in response to lawful requests, such as
        subpoenas or court orders, or in compliance with applicable laws. Additionally, we may share account or other
        personally-identifiable information when we believe it is necessary to comply with law, to protect our interests
        or property, to prevent fraud or other illegal activity perpetrated through the Service or using the Memtones
        name, or to prevent imminent harm. This may include sharing personally-identifiable information with other
        companies, lawyers, agents or government agencies.
      </p>
      <p>
        If the ownership of all or substantially all of the Memtones business, or individual business units or assets
        owned by Memtones that are related to the Service, were to change, your personally-identifiable information may
        be transferred to the new owner. In any such transfer of information, your personally-identifiable information
        would remain subject to this section.
      </p>
      <h4>Updating or Deleting Your Personally-Identifiable Information</h4>
      <p>
        You have at all times the right to delete your account with the Memtones Service by following the instructions
        available through the Service. After your account is deleted we will retain aggregate or anonymous data
        collected through the Service, including Activity Data, which may be used by Memtones and shared with third
        parties in any manner.
      </p>
      <h4>California Privacy Rights</h4>
      <p>
        California Civil Code Section 1798.83 entitles residents of the State of California to request from a business,
        with whom that resident has an established business relationship, certain information regarding the types of
        personal information the business shares with third parties for direct marketing purposes by such third party
        and the identities of the third parties with whom the business has shared such information during the
        immediately preceding calendar year. If you would like to request a copy of this information disclosure from
        Memtones, please contact us at support@memtones.com.
      </p>
      <h4>Links</h4>
      <p>
        The Service may contain links to other websites. We are not responsible for the privacy practices of other
        websites. We encourage users to be aware when they leave the Service to read the privacy statements of other
        websites that collect personally identifiable information. This Privacy Policy applies only to information
        collected by Memtones via the Service.
      </p>
      <h4>Information Security</h4>
      <p>
        Memtones has implemented administrative and technical safeguards it believes are sufficient to protect the
        confidentiality, integrity, and availability of your access credentials. However, we believe that a determined
        attacker with sufficient resources could defeat those safeguards and may, as a result, gain access to the
        information we seek to protect.
      </p>
      <h4>Do Not Track</h4>
      <p>The Service is not designed to respond to "do not track" signals sent by some browsers.</p>
      <h4>Contact Us</h4>
      <p>
        To understand more about our Privacy Policy, access your information, or ask questions about our privacy
        practices or issue a complaint, please contact us at privacy@memtones.app.
      </p>
      <p>
        <em>Last revised on 6 May 2023.</em>
      </p>
    </section>
  </div>
);
export default Privacy;
