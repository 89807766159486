import './App.css';

const TOS = () => (
  <div className="main-container">
    <section style={{ textAlign: 'left', paddingLeft: 10, paddingRight: 10, maxWidth: 756 }}>
      <h2>Memtones Terms of service</h2>
      <p>
        These terms and conditions ("Conditions") apply to the entire contents of the Service under the domain name
        www.memtones.app ("Website"), the Memtones mobile app ("App"), and to any correspondence by email between us and
        you, collectively known as the "Service". Please read these terms carefully before using the Service. Using the
        Service indicates that you accept these terms regardless of whether or not you purchase any software from us. If
        you do not accept these terms, do not use the Service. The Service and all of its content, including but not
        limited to, all text and images are owned and copyrighted by Memtones LLC ("Memtones").
      </p>
      <ol>
        <li>
          <b>Introduction</b>
          <p>
            You may access areas of the Service without registering your details with us. By accessing any part of the
            Service, you shall be deemed to have accepted this legal notice in full. If you do not accept this legal
            notice in full, you must leave the Service immediately. Memtones reserves the right, at any time and from
            time to time, temporarily or permanently, in whole or in part, to: modify or discontinue the Service with or
            without notice; limit the Service's availability to any person, geographic area or jurisdiction we choose;
            charge fees in connection with the use of the Service; modify and/or waive any fees charged in connection
            with the Service; and/or offer opportunities to some or all users of the Service. You agree that neither we
            nor any affiliated entity shall be liable to you or to any third party for any modification, suspension or
            discontinuance of the Service, in whole or in part, or of any service, software, content, submission,
            feature or product offered through the Service. Your continued use of the Service after such changes will
            indicate your acceptance of such changes. You should check the Website from time to time to review the then
            current legal notice. Certain provisions of this legal notice may be superseded by expressly designated
            legal notices or terms located on particular pages at the Service.
          </p>
        </li>
        <li>
          <b>License and Conditions</b>
          <p>
            Memtones grants you a limited license to access and make use of the Service. As a condition of such license,
            you agree:
          </p>
          <ol>
            <li>
              not to download or modify any part of the Service, except with the express and prior written consent of
              Memtones;
            </li>
            <li>not to download or copy any account information for the benefit of another merchant;</li>
            <li>not to collect or make any use of any product listings, descriptions, or prices;</li>
            <li>not to resell or make any commercial use of the Service or its contents;</li>
            <li>
              not to reproduce, duplicate, copy, sell, resell or otherwise exploit the Service for any commercial
              purpose without express written consent of Memtones;
            </li>
            <li>not to make any derivative use of the Service or its contents;</li>
            <li>
              not to frame or utilize framing techniques to enclose any trademark, logo, or other proprietary
              information (including images, text, page layout, or form) of Memtones and its group companies without
              express written consent of Memtones;
            </li>
            <li>
              not to use any meta tags or any other "hidden text" utilizing the Memtones name or trademarks without the
              express written consent of Memtones; and
            </li>
            <li>
              not reproduce or store any part of the Service in any other Service or include any part of the Service in
              any public or private electronic retrieval system or service without prior written permission from
              Memtones.
            </li>
          </ol>
          <p>
            The copyright and other intellectual property rights in all material on the Service (including without
            limitation text, photographs and graphics) are owned by Memtones or its licensors. If you breach any of the
            terms in this legal notice, your permission to use the Service automatically terminates. You are also
            advised that Memtones will aggressively enforce its intellectual property rights to the fullest extent of
            the law, including the seeking of criminal prosecution.
          </p>
          <p>
            All trademarks of Memtones indicated on the Service are trademarks of Memtones or its group companies in the
            United States and other countries. Without the prior consent of Memtones, you agree not to display or use in
            any manner the Memtones trademarks.
          </p>
          <p>Any rights not expressly granted in these terms are reserved.</p>
        </li>
        <li>
          <b>Service Access</b>
          <p>
            While Memtones attempts to ensure that the Service is normally available 24 hours a day, Memtones shall not
            be liable if for any reason the Service is unavailable at any time or for any period.
          </p>
          <p>
            Access to the Service may be suspended temporarily and without notice in the case of system failure,
            maintenance or repair or for reasons beyond the control of Memtones.
          </p>
        </li>
        <li>
          <b>Visitor Material and Conduct</b>
          <p>
            Other than personally identifiable information, which is covered under the Privacy Policy, any material you
            transmit or post to the Service shall be considered non-confidential and non-proprietary. Memtones shall
            have no obligations with respect to such material. Memtones and its designees shall be free to copy,
            disclose, distribute, incorporate and otherwise use such material and all data, images, sounds, text and
            other things embodied therein for any and all commercial or non-commercial purposes.
          </p>
          <p>You are prohibited from posting or transmitting to or from the Service any material:</p>
          <ol>
            <li>
              that is threatening, defamatory, obscene, indecent, seditious, offensive, pornographic, abusive, liable to
              incite racial hatred, discriminatory, menacing, scandalous, inflammatory, blasphemous, in breach of
              confidence, in breach of privacy or which may cause annoyance or inconvenience; or
            </li>
            <li>for which you have not obtained all necessary licenses and/or approvals; or</li>
            <li>
              which constitutes or encourages conduct that would be considered a criminal offense, give rise to civil
              liability, or otherwise be contrary to the law of or infringe the rights of any third party, in any
              country in the world; or
            </li>
            <li>
              which is technically harmful (including, without limitation, computer viruses, logic bombs, Trojan horses,
              worms, harmful components, corrupted data or other malicious software or harmful data).
            </li>
          </ol>
          <p>
            You may not misuse the Service (including, without limitation, by hacking; using robots, spiders, data
            mining or similar data gathering tools; and automated submission of data). Memtones shall fully co-operate
            with any law enforcement authorities or court order requesting or directing Memtones to disclose the
            identity or locate anyone posting any material in breach of paragraph 4(b) or paragraph 4(c).
          </p>
        </li>
        <li>
          <b>Links to and from Other Services</b>
          <p>
            Links to third party Services on the Service are provided solely for your convenience. If you use these
            links, you leave the Service. Memtones has not reviewed all of these third party Services and does not
            control and is not responsible for these Services or their content or availability. Memtones therefore does
            not endorse or make any representations about them, or any material found there, or any results that may be
            obtained from using them. If you decide to access any of the third party Services linked to the Service, you
            do so entirely at your own risk.
          </p>
          <p>
            If you would like to link to the Service, you may only do so on the basis that you link to, but do not
            replicate, the home page of the Service, and subject to the following conditions:
          </p>
          <ol>
            <li>you do not remove, distort or otherwise alter the size or appearance of the Memtones logo;</li>
            <li>you do not create a frame or any other browser or border environment around the Service;</li>
            <li>you do not in any way imply that Memtones is endorsing any products or services other than its own;</li>
            <li>
              you do not misrepresent your relationship with Memtones nor present any other false information about
              Memtones;
            </li>
            <li>
              you do not otherwise use any Memtones trademarks displayed on the Service without express written
              permission from Memtones;
            </li>
            <li>you do not link from a Service that is not owned by you; and</li>
            <li>
              your Service does not contain content that is distasteful, offensive or controversial, infringes any
              intellectual property rights or other rights of any other person or otherwise does not comply with all
              applicable laws and regulations.
            </li>
            <li>
              Memtones expressly reserves the right to revoke the license granted in Section 2 for breach of these terms
              and to take any action it deems appropriate.
            </li>
          </ol>
          <p>
            You shall fully indemnify Memtones for any loss or damage suffered by Memtones or any of its group companies
            for breach of paragraph 5(b).
          </p>
        </li>
        <li>
          <b>Disclaimer</b>
          <p>
            While Memtones attempts to ensure that the information on the Service is correct, Memtones does not warrant
            the accuracy and completeness of the material on the Service. Memtones may make changes to the material on
            the Service, or to the products and prices described in it, at any time without notice. The material on the
            Service may be out of date, and Memtones makes no commitment to update such material.
          </p>
          <p>
            The material on the Service is provided "as is" and "as available" basis, without any conditions, warranties
            or other terms of any kind. Accordingly, to the maximum extent permitted by law, Memtones provides you with
            the Service on the basis that Memtones excludes all representations, warranties (either express or implied),
            conditions and other terms (including, without limitation, the implied warranties of merchantability,
            fitness for a particular purpose, non-infringement and the use of reasonable care and skill) which but for
            this legal notice might have effect in relation to the Service.
          </p>
        </li>
        <li>
          <b>Liability</b>
          <p>
            Memtones, any other party (whether or not involved in creating, producing, maintaining or delivering this
            Service), and any of the Memtones affiliated companies and the officers, directors, employees, shareholders
            or agents of any of them, exclude all liability and responsibility for any amount or kind of loss or damage
            that may result to you or a third party (including without limitation, any direct, indirect, special,
            punitive, exemplary or consequential loss or damages, or any loss of income, profits, goodwill, data,
            contracts, use of money, or loss or damages arising from or connected in any way to business interruption,
            and whether in tort [including without limitation negligence], contract or otherwise) in connection with the
            Service in any way or in connection with the use, inability to use or the results of use of the Service, any
            Services linked to the Service or the material on such Services, including but not limited to loss or damage
            due to viruses that may infect your computer equipment, software, data or other property on account of your
            access to, use of, or browsing the Service or your downloading of any material from the Service or any
            Services linked to the Service.
          </p>
          <p>
            If your use of material on the Service results in the need for servicing, repair or correction of equipment,
            software or data, you assume all costs thereof.
          </p>
        </li>
        <li>
          <b>General</b>
          <ol>
            <li>
              <p>
                <strong>Governing Law and Jurisdiction.</strong>
                This legal notice shall be governed by and construed in accordance with the laws of the state of South
                Carolina. Any dispute arising out of or connected with this legal notice shall be subject to the
                exclusive jurisdiction of the courts of the state of South Carolina.
              </p>
            </li>
            <li>
              <p>
                <strong>Entire Agreement.</strong>
                The Conditions constitute the entire agreement between you and Memtones and govern your use of the
                Service, superseding any prior agreements between you and Memtones with respect to the Service. You will
                be subject to additional terms and conditions that will apply when you use or purchase Memtones services
                and products.
              </p>
            </li>
            <li>
              <p>
                <strong>Waiver and Severability of Terms.</strong>
                The failure of Memtones to exercise or enforce any right or provision of the Conditions shall not
                constitute a waiver of such right or provision. If any provision of the Conditions is found by a court
                of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor
                to give effect to the parties' intentions as reflected in the provision, and the other provisions of the
                Conditions remain in full force and effect.
              </p>
            </li>
            <li>
              <p>
                <strong>Statute of Limitations.</strong>
                You agree that regardless of any statute or law to the contrary, any claim or cause of action arising
                out of or related to the Conditions must be filed within one (1) year after such claim or cause of
                action arose or be forever barred.
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p>Issue Date: May 6, 2023</p>
    </section>
  </div>
);
export default TOS;
